import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/* import { FormsModule } from '@angular/forms'; */
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgxNumberFormatModule } from 'ngx-number-format';

import { ContainerComponent } from './components/container/container.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { LoginComponent } from './components/login/login.component';
import { ErrorComponent } from './components/error/error.component';

import { OperationsDataService } from './services/operations-data/operations-data.service';
import { MessageService } from './services/message-services/message-service.service';
import { PaymentsComponent } from './components/payments/payments.component';
import { PaymentSummaryComponent } from './components/payment-summary/payment-summary.component';

import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { PaymentDaviviendaComponent } from './components/payment-davivienda/payment-davivienda.component';
import { PaymentOpenpayComponent } from './components/payment-openpay/payment-openpay.component';
import { NgxSpinnerModule } from 'ngx-spinner';

const appSteps: Routes = [
  { path: '', component: LoginComponent },
  { path: 'reload/:paymentToken', pathMatch: 'full', component: AppComponent },
  { path: 'login', component: LoginComponent },
  { path: 'invoiceList', component: InvoiceListComponent },
  { path: 'payment-summary', component: PaymentSummaryComponent },
  { path: 'payments', 
    component: PaymentsComponent,
    pathMatch: 'full' 
  },
  {
    path: 'paymentez/:id/:status',
    component: PaymentDaviviendaComponent,
    pathMatch: 'full',
  },
  {
    path: 'openpay/:order_id/:id/:status',
    component: PaymentOpenpayComponent,
    pathMatch: 'full',
  },
  { path: 'error', component: ErrorComponent },
  /*{ path: 'ServiciosRealizar', component : ServrealizarComponent},
  { path: 'ListaTaller', component : ListaTallerComponent},
  { path: 'FechaCita', component : FechacitaComponent},
  { path: 'ConfirmarInformacion', component : ConfircitaComponent} */
];

export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: false,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'COP $ ',
  suffix: '',
  thousands: '.',
  nullable: true,
};

@NgModule({
  declarations: [
    AppComponent,
    ContainerComponent,
    LoginComponent,
    InvoiceListComponent,
    PaymentsComponent,
    PaymentSummaryComponent,
    ErrorComponent,
    PaymentDaviviendaComponent,
    PaymentOpenpayComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appSteps),
    HttpClientModule,
    /*  FormsModule, */
    MatCardModule,
    MatFormFieldModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatInputModule,
    MatGridListModule,
    MatCheckboxModule,
    MatSelectModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    NgxNumberFormatModule,
    NgxSpinnerModule,
  ],
  providers: [
    OperationsDataService,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
