<div class="container-fluid" style="height: 100%;">
    <div class="row">
        <div class="col-12">
            <nav class="navbar navbar-dark" [ngStyle]="{'background-color':colorMarca}">
                <div class="container-fluid  main-logo ">
                    <img [src]="logoCliente" class="size-logo" alt="MDB Logo" loading="lazy" />
                </div>
            </nav>

        </div>

    </div>
    <div class="row mb-0">
        <div class="col-12">
            <div class="container" style="height: 85%;">
                <div class="row">
                    <div class="col-md-12 ">
                        <div class="text-center shadow bg-white rounded mt-5">
                            <div class="card-header "
                                [ngStyle]="{ 'font-family': fuente, 'border-radius': '22px 22px 0px 0px', 'background-color':colorMarca }">
                                <h2 [ngStyle]="{ 'font-family': fuente, 'margin':'0px', 'color':colorTexoMarca }">Módulo
                                    de Pagos
                                </h2>
                            </div>
                            <div class="card-body bg-white mr-5 ml-5" cdkScrollable>
                                <div class="container-fluid">
                                    <div class="row m-0">
                                        <div class="col-md-6 text-left m-0">
                                            <span
                                                [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'14px'}">
                                                <span
                                                    [ngStyle]="{'color': 'black','font-family':fuente,'font-weight': 'bold'}">Nit:</span>
                                                {{ nitClient | uppercase}} </span> &nbsp;&nbsp; <span
                                                [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'12px'}"><span
                                                    [ngStyle]="{'color': 'black','font-family':fuente,'font-weight': 'bold'}">
                                                    Cliente:</span> {{ invoiceClient | uppercase}}</span>
                                        </div>
                                        <div class="col-md-6 text-right m-0">
                                            <span
                                                [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'14px'}">Sus
                                                facturas / Documentos por pagar</span>
                                        </div>
                                        <hr>
                                    </div>


                                    <div class="row ">
                                        <div class="col-md-12">
                                            <div *ngFor="let invoice of invoices;"
                                                class="card shadow mb-3  bg-white rounded-lg"
                                                style="border:none !important; background-color:#e9e3e38f !important;">
                                                <div *ngIf="invoice.tipo !=='ANTI'" class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="row">
                                                                <div class="col-md-6 text-left"
                                                                    [ngStyle]="{ 'font-family': fuente }">
                                                                    <span *ngIf="invoice.tipo !=='HN'"
                                                                        style="font-size:12px; font-weight: bold;">
                                                                        <span
                                                                            style="color: gray; font-weight: bold; font-size:14px">Factura
                                                                            número:</span> {{ invoice.tipo | uppercase}}
                                                                        {{
                                                                        invoice.numero}}</span>
                                                                    <span *ngIf="invoice.tipo ==='HN'"
                                                                        style="font-size:12px; font-weight: bold;">
                                                                        <span
                                                                            style="color: gray; font-weight: bold; font-size:14px">Negocio
                                                                            número:</span>{{ invoice.tipo| uppercase}}
                                                                        {{
                                                                        invoice.numero}}</span>
                                                                </div>
                                                                <div class="col-md-6 text-left"
                                                                    [ngStyle]="{ 'font-family': fuente }">
                                                                    <span style="font-size:12px; font-weight: bold;">
                                                                        <span
                                                                            style="color: gray; font-weight: bold; font-size:14px">Descripción:</span>
                                                                        {{ invoice.descripcion_doc | uppercase}}</span>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-6 text-left"
                                                            [ngStyle]="{ 'font-family': fuente }">
                                                            <span
                                                                [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold'  }">
                                                                <span
                                                                    style="color: gray; font-weight: bold; font-size:14px">Fecha:</span>
                                                                {{ invoice.fecha_aux}} </span>
                                                        </div>
                                                        <div class="col-md-6 text-left"
                                                            [ngStyle]="{ 'font-family': fuente }">
                                                            <span style="font-size:12px; font-weight: bold;"> <span
                                                                    style="color: gray; font-weight: bold; font-size:14px">Vence:</span>
                                                                {{ invoice.vence_aux}}</span>
                                                        </div>

                                                    </div>
                                                    <hr class="m-0">
                                                    <div class="row mt-4">
                                                        <div class="col-md-12">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <div class="col-5 text-left mt-1 p-2">
                                                                        <img src="../../../assets/img/IconoSaldo.svg"
                                                                            alt="">
                                                                        <span class="ml-2"
                                                                            [ngStyle]="{ 'font-family': fuente, 'font-size':'15px' ,'font-weight': 'bold','color':'#808080'}">Saldo:
                                                                            &nbsp;&nbsp;&nbsp;&nbsp; <span
                                                                                style="font-size: 18px">
                                                                                {{ invoice.saldo | currency :'$'}}
                                                                            </span></span>
                                                                    </div>

                                                                    <div class="col-7 mt-2">
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <div
                                                                                    class="col-md-3 col-12 text-left mt-2 mb-2">
                                                                                    <img src="../../../assets/img/IconoPagar.svg"
                                                                                        alt="">
                                                                                    <span class="ml-2 mr-2"
                                                                                        [ngStyle]="{ 'font-family': fuente, 'font-size':'15px' ,'font-weight': 'bold','color':'#808080'}">Pagar:</span>


                                                                                    <input *ngIf="invoice.tipo !=='HN'"
                                                                                        type="checkbox"
                                                                                        id="check{{invoice.numero}}"
                                                                                        class="form-check-input mt-2 ml-2"
                                                                                        value="{{invoice.saldo}}"
                                                                                        (change)="onCheckboxChange($event,invoice.numero)">
                                                                                    <input *ngIf="invoice.tipo ==='HN'"
                                                                                        type="checkbox"
                                                                                        class="form-check-input mt-2 ml-2"
                                                                                        value="{{invoice.saldo}}"
                                                                                        (change)="onCheckboxHNChange($event,invoice.numero,invoice.hN_seq_forma)">
                                                                                </div>


                                                                                <div *ngIf="invoice.tipo !=='HN'"
                                                                                    class="col-md-4 col-12 text-left mb-2 ">

                                                                                    <div class="form-check text-left">
                                                                                        <input type="radio"
                                                                                            class="form-check-input"
                                                                                            id="{{invoice.numero}}pagar"
                                                                                            name="{{invoice.numero}}"
                                                                                            (change)="pagarSaldo($event,invoice.numero)"
                                                                                            checked disabled>
                                                                                        <label class="form-check-label"
                                                                                            [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold','color':'#808080'}"
                                                                                            for="materialUnchecked">Pagar
                                                                                            saldo
                                                                                            total</label>
                                                                                    </div>
                                                                                    <div class="form-check text-left">
                                                                                        <input type="radio"
                                                                                            class="form-check-input"
                                                                                            id="{{invoice.numero}}modificar"
                                                                                            name="{{invoice.numero}}"
                                                                                            (change)="modificarValor($event,invoice.numero)"
                                                                                            disabled>
                                                                                        <label class="form-check-label"
                                                                                            [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold','color':'#808080'}"
                                                                                            for="materialChecked">Modificar
                                                                                            valor</label>
                                                                                    </div>

                                                                                </div>

                                                                                <div *ngIf="invoice.tipo ==='HN'"
                                                                                    class="col-md-4 col-12 text-left mb-2 ">

                                                                                    <div class="form-check text-left">
                                                                                        <input type="radio"
                                                                                            class="form-check-input"
                                                                                            id="{{invoice.numero}}{{invoice.hN_seq_forma}}pagar"
                                                                                            name="{{invoice.numero}}"
                                                                                            (change)="pagarSaldoHN($event,invoice.numero,invoice.hN_seq_forma)"
                                                                                            checked disabled>
                                                                                        <label class="form-check-label"
                                                                                            [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold','color':'#808080'}"
                                                                                            for="materialUnchecked">Pagar
                                                                                            saldo
                                                                                            total</label>
                                                                                    </div>
                                                                                    <div class="form-check text-left">
                                                                                        <input type="radio"
                                                                                            class="form-check-input"
                                                                                            id="{{invoice.numero}}{{invoice.hN_seq_forma}}modificar"
                                                                                            name="{{invoice.numero}}"
                                                                                            (change)="modificarValorHN($event,invoice.numero,invoice.hN_seq_forma)"
                                                                                            disabled>
                                                                                        <label class="form-check-label"
                                                                                            [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold','color':'#808080'}"
                                                                                            for="materialChecked">Modificar
                                                                                            valor</label>
                                                                                    </div>

                                                                                </div>

                                                                                <div class="col-md-5 col-12 text-left">
                                                                                    <input *ngIf="invoice.tipo !=='HN'"
                                                                                        class="form-control"
                                                                                        placeholder="Saldo"
                                                                                        ngxNumberFormat="###,###,###"
                                                                                        value="{{ invoice.saldo | currency :'' }}"
                                                                                        id="{{invoice.numero}}"
                                                                                        (blur)="validatedValue($event.target.value, invoice.numero)"
                                                                                        disabled autofocus>

                                                                                    <input *ngIf="invoice.tipo ==='HN'"
                                                                                        class="form-control"
                                                                                        placeholder="Saldo"
                                                                                        ngxNumberFormat="###,###,###"
                                                                                        value="{{ invoice.saldo | currency :'' }}"
                                                                                        id="{{invoice.numero}}{{invoice.hN_seq_forma}}"
                                                                                        (blur)="validatedValueHN($event.target.value,invoice.numero,invoice.hN_seq_forma)"
                                                                                        disabled autofocus>

                                                                                </div>

                                                                            </div>

                                                                        </div>



                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>

                                                    </div>
                                                    <hr class="m-0 mt-4">

                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <div class="col-12 text-left d-flex flex-row">
                                                                        <div class="mr-2 mt-2">
                                                                            <img src="../../../assets/img/IconosNota.svg"
                                                                                alt="">
                                                                            <span class="ml-2"
                                                                                [ngStyle]="{ 'font-family': fuente, 'font-size':'15px' ,'font-weight': 'bold','color':'#808080'}">Notas:</span>
                                                                            <span
                                                                                [ngStyle]="{ 'font-family': fuente, 'font-size':'12px' ,'font-weight': 'bold','color':'#808080'}">
                                                                                {{ invoice.notas }}</span>
                                                                        </div>


                                                                    </div>


                                                                </div>

                                                            </div>

                                                        </div>



                                                    </div>

                                                </div>

                                                <div *ngIf="invoice.tipo ==='ANTI'" class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-4">
                                                            <p class="text-left"
                                                                [ngStyle]="{ 'font-family': fuente, 'font-size':'13px' } ">
                                                                <span
                                                                    [ngStyle]="{ 'font-family': fuente, 'font-size':'14px', 'color':'#808080', 'font-weight':'bold'}">Anticipo
                                                                    por concepto</span> <br>
                                                                Use esta opcion si no encuentra su documento a pagar o
                                                                si desea
                                                                realizar un anticipo
                                                            </p>
                                                        </div>
                                                        <div class="col-md-4 col-12 text-center mt-2 mb-2">
                                                            <img src="../../../assets/img/IconoPagar.svg" alt="">
                                                            <span class="ml-2 mr-2"
                                                                [ngStyle]="{ 'font-family': fuente, 'font-size':'15px' ,'font-weight': 'bold','color':'#808080'}">Pagar:</span>
                                                            <input type="checkbox" class="form-check-input mt-2 ml-2"
                                                                value="{{invoice.saldo}}"
                                                                (change)="onCheckboxChangeOther($event)">
                                                        </div>

                                                        <div class="col-md-4 col-12 mt-2 mb-2 text-right">
                                                            <input class="form-control" placeholder="Saldo"
                                                                ngxNumberFormat="###,###,###"
                                                                value="{{ 0 | currency :'' }}" id="otherValue"
                                                                (blur)="validatedOtherValue($event.target.value)"
                                                                disabled autofocus>

                                                        </div>


                                                    </div>

                                                    <div class="row">
                                                        <div class="col-md-12  text-left">
                                                            <label for="" [ngStyle]="{'font-family': fuente}">Motivo
                                                                anticipo</label>
                                                            <select class="form-control"
                                                                (change)="onChangeReason($event.target.value)">
                                                                <option value="0" disabled selected>Seleccione razón
                                                                </option>
                                                                <option value="{{reason.id}}"
                                                                    *ngFor="let reason of advanceReason">
                                                                    {{reason.motivo_anticipo}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-12  text-left">
                                                            <label for=""
                                                                [ngStyle]="{'font-family': fuente}">Observaciones
                                                                anticipo</label>
                                                            <textarea class="form-control" id="observation"
                                                                [(ngModel)]="observationsAdvance" rows="3"></textarea>
                                                        </div>
                                                    </div>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-md-12 text-right">
                                            <button class="btn button-login "
                                            [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton }"
                                                type="submit" (click)="viewPaymentSummary()">CONTINUAR</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-12">
            <footer class="bg-light text-center text-lg-start">
                <!-- Copyright -->
                <div class="text-center p-3 text-white" [ngStyle]="{'background-color':colorMarca}">
                    <p></p>
                </div>
                <!-- Copyright -->
            </footer>
        </div>

    </div>

</div>