import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  DocumentstoPay,
  DocumentstoPayDetail,
  Invoices,
  Business,
} from '../../intefaces/GeneralInterface';
import {
  DaviviendaLinkPayRequest,
  User,
  Order,
  Configuration,
} from '../../intefaces/DaviviendaPayInterface';
import { OpenPayLinqRequest } from '../../intefaces/OpenPayInterface';
import { OperationsDataService } from '../../services/operations-data/operations-data.service';
import { Guid } from 'guid-typescript';
import * as $ from 'jquery';
import { stringify } from '@angular/compiler/src/util';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.css'],
})
export class PaymentSummaryComponent implements OnInit {
  typePayment: string = 'WO'; //La pasarela por defecto es wompy
  publicKey: string = '';
  invoiceClient: string = '';
  nitClient: string = '';
  invoices: Invoices[] = [];
  invoicesPay: Invoices[] = [];
  color: 'white';
  totalPayment: number = 0;
  tolalPaymentString: string;
  bussines: Business;
  colorEmpresa: any;
  colorBotonEmpresa: any;
  urlRedirect: string = '';
  statusBotonModificar: boolean = false;
  private _bussines: Business;
  private _documentstoPayDetail: DocumentstoPayDetail[] = [];
  private _paymentData: DocumentstoPay;
  private referenceId: Guid;
  private _devRefence: string = '';
  private _name: string = '';
  private _lastName: string = '';
  private _email: string = '';
  private _phone: string = '';
  logoCliente: string = '';
  imagenFondo: string = '';
  titulo1: string = '';
  texto1: string = '';
  fuente: string = 'Arial';
  colorMarca: string = '';
  colorTexoMarca: string = '';
  colorFondoBoton: string = '';
  colorTextoBoton: string = '';
  claveCriptografica: string = '';


  /* private _daviviendapay: DaviviendaLinkPayRequest; */
  private _user: User;
  private _order: Order;
  private _configuration: Configuration;
  constructor(
    private router: Router,
    private operationData: OperationsDataService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    //this.urlRedirect = `https://${window.location.host}/`;
    this.referenceId = Guid.create();
    $('#referenceId').val(this.referenceId.toString());
    this.getBussines();
    this.getInvoices();
    this.savePayment();

    if(this._bussines.tipo_pasarela === 'WO'){
      let bodyFirma = {
        referenciaPago: this.referenceId.toString(),
        monto: (this.totalPayment * 100),
        moneda: "COP",
        idEmpresa: this._bussines.id_empresa,
        secreoIntegridad: ""
  
      }
  
      console.log(bodyFirma);
  
      this.operationData.GenerarFirmaCriptografica(bodyFirma).subscribe((response: any) => {
        console.log(response);
        this.claveCriptografica=response;
        
      },error=>{console.log("error ", error)});

    }
    


  }

  private getBussines() {
    // todo: eliminar debugger y el console
    //debugger;
    this._bussines = this.operationData.getDataBussines();
    if (this._bussines == undefined) {
      this.router.navigate(['/'], { queryParams: { paymentToken: localStorage.getItem('_paymentToken') } });
    };

    
      this.typePayment = this._bussines.tipo_pasarela;
      console.log(this.typePayment, 'tipoPayment')
      this.colorEmpresa = {
        'background-color': this._bussines.color_background_marca,
        color: this._bussines.color_texto_marca,
      };
      this.colorBotonEmpresa = {
        'background-color': this._bussines.color_background_boton,
        color: this._bussines.color_texto_boton,
      };
      this.publicKey = this._bussines.llave_pasarela;

      this.logoCliente = `../../../assets/img/${this._bussines.logoEmpresa}`;
      this.imagenFondo = this._bussines.fondo_inicial;
      this.titulo1 = this._bussines.titulo_1;
      this.texto1 = this._bussines.texto_1;
      this.fuente = this._bussines.nombre_fuente;
      this.colorMarca = this._bussines.color_background_marca;
      this.colorTexoMarca = this._bussines.color_texto_marca;
      this.colorFondoBoton = this._bussines.color_background_boton;
      this.colorTextoBoton = this._bussines.color_texto_boton;
      this.urlRedirect = this._bussines.protocolo + "://" + window.location.host + "/"
      //debugger;
      //console.log(this.publicKey, 'publickey');


  }

  private getInvoices() {
    this.invoices = this.operationData.getDataInvoice();
    this.invoices.forEach((item) => {
      if (item.tipo.length > 0) {
        if (item.pagar === true) {
          this.invoicesPay.push(item);
          this.totalPayment += parseFloat(item.valor_pagar.toString());
          this.tolalPaymentString = this.totalPayment.toString() + '00';
          if (this._name.length === 0) {
            this._name = item.nombre;
            this._lastName = item.apellido;
            this._email = item.correo;
            this._phone = item.telefono;
          }
          //this.tolalPaymentString = this.tolalPaymentString.replace('.','');
          //this.tolalPaymentString = this.tolalPaymentString.substring(0,this.totalPayment -2);
          $('#amount-in-cents').val(this.tolalPaymentString);
          let cta_hn = this._bussines.cuenta_hn;
          this._devRefence += `${item.tipo}${item.numero} `;
          let document: DocumentstoPayDetail = {
            tipo_documento: item.tipo,
            numero_documento: item.numero,
            valor_pagado: item.valor_pagar,
            HN_forma: item.hN_forma,
            HN_seq_forma: item.hN_seq_forma,
            id_motivo_anticipo: item.id_motivo_anticipo,
            notas: item.notas,
            cta_anticipo:
              item.tipo === 'ANTI'
                ? item.cta_anticipo
                : item.tipo === 'HN'
                  ? cta_hn
                  : null,
          };
          this._documentstoPayDetail.push(document);

          this.invoiceClient = item.cliente;
          this.nitClient = item.nit.toString();
        }
      }
    });
    $('#amount-in-cents').val(this.tolalPaymentString);

   

  }

  return() {
    this.operationData
      .updatedocumentsPayModify(this.referenceId.toString())
      .subscribe((_data) => {
        this.router.navigate(['/invoiceList']);
      });
  }

  savePayment() {
    this._paymentData = {
      nit: this.invoices[0].nit,
      valor_total: this.totalPayment,
      id_empresa: this._bussines.id_empresa,
      id_transaccion: this.referenceId.toString(),
      cod_confirmacion: '0',
      bd: this._bussines.bd,
      tipo_rc: this._bussines.doc_recibo,
      cuenta_banco: this._bussines.cuenta_banco,
      PaidDocumentsDetails: this._documentstoPayDetail,
    };
    this.spinner.show();
    this.operationData
      .documentPaymentSave(this._paymentData)
      .subscribe((_data) => {
        this.spinner.hide();
        console.log(_data, 'savePayment');
      });
  }

  linkToPaymentezPay() {
    let _daviviendapay: DaviviendaLinkPayRequest = {
      id: this.referenceId.toString(),
      email: this._email,
      name: this._name,
      last_name: this._lastName,
      dev_reference: this._devRefence,
      description: 'Pago facturas',
      amount: this.totalPayment,
      installments_type: 0,
      currency: 'COP',
      partial_payment: true,
      expiration_days: 1,
      success_url: `${this.urlRedirect}?idPaymentez=${this.referenceId.toString()}&status=${'APPROVED'}`,
      failure_url: `${this.urlRedirect}?idPaymentez=${this.referenceId.toString()}&status=${'DECLINED'}`,
      pending_url: `${this.urlRedirect}?idPaymentez=${this.referenceId.toString()}&status=${'DECLINED'}`,
      review_url: `${this.urlRedirect}?idPaymentez=${this.referenceId.toString()}&status=${'DECLINED'}`,
    };
    console.log(_daviviendapay);

    this.spinner.show();
    this.statusBotonModificar = true;
    this.operationData.generateLinkToPay(_daviviendapay).subscribe((_data) => {
      console.log(_data)
      console.log(_data.data.payment.payment_url, 'savePayment');
      this.spinner.hide();
      window.location.href = _data.data.payment.payment_url;
    }, error => {
      this.statusBotonModificar = false;
      console.log(error)
    });
  }

  linkToOpenPay() {
    let _openPay: OpenPayLinqRequest = {
      amount: this.totalPayment,
      description: 'Pago facturas',
      order_id: this.referenceId.toString(),
      currency: 'COP',
      iva: 0.0,
      redirect_url: `${this.urlRedirect}${'?order_id='}${this.referenceId.toString()}${'&status=PENDING'}`,
      customer: {
        email: this._email,
        name: this._name,
        last_name: this._lastName,
        phone_number: this._phone,
      },
    };

    console.log(_openPay)
    this.spinner.show();
    this.statusBotonModificar = true;
    this.operationData.generateLinkToOpenPay(_openPay).subscribe((_data) => {
      this.spinner.hide();
      console.log(_data, 'savePayment');
      if (_data.checkout_link !== null) {
        window.location.href = _data.checkout_link;
      } else {
        this.statusBotonModificar = false;
      }
    }, error => {
      this.statusBotonModificar = false;
    });
  }
}
