<div class="">
    <mat-toolbar   style="align-content: center !important; background: #cccccc;">
      <mat-toolbar-row>
        <span style="font-size: 1.8rem;">MODULO DE PAGOS</span>
      </mat-toolbar-row>
    </mat-toolbar>
  
    <div class="info-payment">
      
      <div class="container-info-payment">
        <div class="container">
         <!--  <h2  class="color-declined text-header">UPSSSS !!!!! LO SENTIMOS EN ESTOS MOMENTOS EL SERVICIO NO ESTA DISPONBILE</h2> -->
       
          <div class=" container-data-payment">                         
            <img src="assets/error-500.png" style="width: inherit;">
          </div>
        </div>       
      </div>
  </div> 
  </div>
