import { Component,HostListener, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { map, shareReplay } from 'rxjs/operators';
import {Subscription} from 'rxjs';

import { MessageService } from '../../services/message-services/message-service.service'
import { OperationsDataService } from '../../services/operations-data/operations-data.service';
import { Business,Invoices } from 'src/app/intefaces/GeneralInterface';

import Swal from 'sweetalert2'

import {ErrorStateMatcher} from '@angular/material/core';
import { InvoiceListComponent } from '../invoice-list/invoice-list.component';
import * as Bowser from "bowser";
import * as $ from 'jquery';

import { NgxSpinnerService } from 'ngx-spinner';

/** Error when invalid control is dirty, touched, or submitted. */
/* export class LoginErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
} */


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  /* matcher:any = new LoginErrorStateMatcher(); */
  bussines: Business;
  colorEmpresa: any;
  colorBotonEmpresa: any;
  bussinesName : string = ''; 
  imageHeader: string = '';
  clientExist = true;
  browserName: string = '';
  isvalidBrowser: boolean = false;
  is1024: boolean = true;
  is700: boolean = false;
  is300: boolean = false;
  breakpoint: number;
  //new Mateo
  logoCliente:string='';
  imagenFondo:string='';
  titulo1:string='';
  texto1:string='';
  fuente:string='Arial';
  colorMarca:string='';
  colorTexoMarca:string='';
  colorFondoBoton:string='';
  colorTextoBoton:string='';
  disabledBtnContinuar :boolean =true;





  private _subscriptionBussines: Subscription;
  private _invoices: Invoices[];
  constructor(private router: Router,
              private operationsData : OperationsDataService,
              private messageService : MessageService,
              private spinner: NgxSpinnerService
              ) { 
     
  }

 /*  frmLogin= this.fb.group({
    identificationType:['',Validators.required],
    identificationNumber:['',Validators.required]
  });*/

  ngOnInit(): void {
    this.breakpoint = window.innerWidth <= 400 ? 1 : 2;
    this.subscribeBussines();
    const browser = Bowser.getParser(window.navigator.userAgent);
    let _browserName: string =  browser.getBrowserName();

    this.isvalidBrowser = (browser.getBrowserName() === "Chrome","Firefox","Microsoft Edge")?true:false;
    if (_browserName === "Chrome" || _browserName === "Firefox" || _browserName === "Microsoft Edge" || _browserName === "Safari"  || _browserName === "Opera") {
       this.isvalidBrowser = true; 
    } else {
       this.isvalidBrowser = false;
       this.browserName =  _browserName;
    }
  }


  
  ngOnDestroy():void {
    try{
     this._subscriptionBussines.unsubscribe();
    } catch (error) {
           
    }
  }

  @HostListener('window:resize',['$event'])
  onResize(event) {
     this.validateHeader();
  }

  private validateHeader(){
      if (window.innerWidth>=1024){
          this.imageHeader = this.bussines.encabezado1;
          this.is1024 = true;
          this.is700 = false;
          this.is300 = false;
      } else if (window.innerWidth<=1024 && window.innerWidth>700 ){
          this.imageHeader = this.bussines.encabezado2;
          this.is1024 = false;
          this.is700 = true;
          this.is300 = false;
      } else {
          this.imageHeader = this.bussines.encabezado3;
          this.is1024 = false;
          this.is700 = false;
          this.is300 = true;
      }
  } 

  private subscribeBussines (): void {
    this._subscriptionBussines = this.messageService.getMessageBussines().subscribe((_data) => {
      this.bussines = _data;
      //this.imageHeader = this.bussines.encabezado1;
      
      this.bussinesName = this.bussines.nombre_empresa;
      this.colorEmpresa =   {'background-color' : this.bussines.color_background_marca , 'color' : this.bussines.color_texto_marca};
      
      this.logoCliente = `../../../assets/img/${this.bussines.logoEmpresa}`;
      this.imagenFondo = this.bussines.fondo_inicial;
      this.titulo1 = this.bussines.titulo_1;
      this.texto1 = this.bussines.texto_1;
      this.fuente=this.bussines.nombre_fuente;
      this.colorMarca = this.bussines.color_background_marca;
      this.colorTexoMarca = this.bussines.color_texto_marca;
      this.colorFondoBoton = this.bussines.color_background_boton;
      this.colorTextoBoton = this.bussines.color_texto_boton;


      this.colorBotonEmpresa =   {'background-color' : this.bussines.color_background_boton , 'color' : this.bussines.color_texto_boton};
      this.operationsData.getAdvanceReason(this.bussines.id_empresa).subscribe();
      this.validateHeader();
    });
  }

  onSubmit(){
    /* if (!this.frmLogin.valid) {
      console.log('failled');
      return;
    } else { */
      //let identificationNumber = this.frmLogin.get('identificationNumber').value;
      this.spinner.show();
      let identificationNumber = $("#identificationNumber").val();
      
      this.operationsData.getInvoices(identificationNumber.toString(),this.bussines.bd).subscribe((_data) =>{

        this.spinner.hide();
       
        this._invoices = _data; 
        if (this._invoices.length === 0) { 

          this.clientExist = false;
          Swal.fire({
            icon: 'error',
            title: 'Documento invalido',
            text: 'Este documento no existe!'
          });

        } else {
          this.router.navigate(['/invoiceList']);
        }
     }, error =>{
        console.log(error);
        
     }); 
  }

  onKeyPressInput(e){
    if (e.keyCode < 45 || e.keyCode > 57) e.returnValue = false;
    
  }

  enabledButton($event){
    let identificationNumber = $("#identificationNumber").val();
    console.log(identificationNumber);
    
    if(identificationNumber != ""){
      this.disabledBtnContinuar =false;
    }else{
      this.disabledBtnContinuar =true;

    }    
  }

  returnLogin(){
       this.clientExist = true;
       //this.frmLogin.get('identificationNumber').setValue('');
       //this.frmLogin.get('identificationType').setValue('');
  } 
}

