import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import * as moment from 'moment';
import { PaymentSummary } from '../../intefaces/GeneralInterface';
import { OperationsDataService } from '../../services/operations-data/operations-data.service';

@Component({
  selector: 'app-payment-davivienda',
  templateUrl: './payment-davivienda.component.html',
  styleUrls: ['./payment-davivienda.component.css'],
})
export class PaymentDaviviendaComponent implements OnInit {
  paymentSummary: PaymentSummary = {};
  textButton: string = '';
  status: string = '';
  date_transacction: string = '';
  colorEmpresa: any;
  colorBotonEmpresa: any;

  private _idTransaccion;
  private _status;
  private _paymentResult: any;
  logoCliente:string = '';
  fondo:string='';
  fuente:string='';
  colorMarca:string='';



  private _reference: string;
  private _id: string;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private operationData: OperationsDataService
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this._idTransaccion = params['id'];
    });
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params) => {
      this._idTransaccion = params.id;
      this.status = params.status;
      if (this._idTransaccion) {
        this._reference = this._idTransaccion;

        this.operationData
          .getPaymentSummary(this._reference)
          .subscribe((_data) => {
            this.paymentSummary = _data;
            this.colorEmpresa = {
              'background-color': this.paymentSummary.color_background_marca,
              color: this.paymentSummary.color_texto_marca,
            };
            this.colorBotonEmpresa = {
              'background-color': this.paymentSummary.color_background_boton,
              color: this.paymentSummary.color_texto_boton,
            };

            this.date_transacction = moment(
              this.paymentSummary.fecha_transaccion
            ).format('DD/MM/YYYY hh:mm a');
            this.textButton = this.paymentSummary.texto_boton_regreso;

            this.operationData.getStatusInvoice(this.paymentSummary.id_transaccion).subscribe((response:any)=>{
              this.status = String(response.result).trim();
               if (this.status.toUpperCase() === 'APPROVED') {
              let dataPay = {
                id_transaccion: this._reference,
                cod_confirmacion: this._id,
                bd: this.paymentSummary.bd,
              };
              this.operationData
                .documentPaymentSaveErp(dataPay)
                .subscribe((_data) => {
                  console.log(_data);
                });
            } else {
              let dataPay = {
                id_transaccion: this._reference,
                cod_confirmacion: this._id,
                bd: this.paymentSummary.bd,
                status: this.status.toUpperCase(),
              };
              this.operationData.saveFailedWompy(dataPay).subscribe((_data) => {
                console.log(_data);
              });
            }

            });

            this.fondo= this.paymentSummary.fondo_resumen;
            this.logoCliente= `../../../assets/img/${this.paymentSummary.logoEmpresa}`;
            this.fuente=this.paymentSummary.nombre_fuente;
            this.colorMarca = this.paymentSummary.color_background_marca;  

           
          });
      }
    });
  }

  newPayment() {
    const Url = `${this.paymentSummary.url_widget}?${'paymentToken'}=${
      this.paymentSummary.token_widget
    }`;
    window.location.href = Url;
  }

  returnPageBussines() {
    const Url = this.paymentSummary.pagina_web;
    window.location.href = Url;
  }
}
