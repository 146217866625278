<nav class="navbar navbar-dark" [ngStyle]="{'background-color':colorMarca}">
    <div class="container-fluid  main-logo ">
        <img [src]="logoCliente" height="60" alt="MDB Logo" loading="lazy" />
    </div>
</nav>
<div class="container">
    <div class="row">
        <div class="col-md-12 ">
            <div class="text-center shadow bg-white rounded mt-5">
                <div class="card-header "
                    [ngStyle]="{ 'font-family': fuente, 'border-radius': '22px 22px 0px 0px', 'background-color':colorMarca }">
                    <h2 [ngStyle]="{ 'font-family': fuente, 'margin':'0px', 'color':colorTexoMarca }">Confirmación de la
                        transacción
                    </h2>
                </div>
                <div class="card-body bg-white mr-5 ml-5" cdkScrollable>
                    <div class="container-fluid">
                        <div class="row m-0">
                            <div class="col-md-12 text-center m-0">
                                <span
                                    [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'14px'}">
                                    <span
                                        [ngStyle]="{'color': 'black','font-family':fuente,'font-weight': 'bold'}">Nit:</span>
                                    {{ nitClient | uppercase}} </span> &nbsp;&nbsp; <span
                                    [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'12px'}"><span
                                        [ngStyle]="{'color': 'black','font-family':fuente,'font-weight': 'bold'}">
                                        Cliente:</span> {{ invoiceClient | uppercase}}</span>
                            </div>

                        </div>
                        <hr class="m-0 mt-2">

                        <div class="row mt-4">
                            <div class="col-md-12">
                                <p
                                    [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'14px'}">
                                    A continuación encontrará la información del pago a realizar</p>
                                <p
                                    [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': '','font-size':'14px'}">
                                    Por favor verifique la información antes de ir a pagar</p>
                                
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-12 text-right">
                                <div class="col-lg-6 col-md-6 col-sm-12" *ngFor="let invoice of invoicesPay;"
                                    style="display: inline-block !important;box-sizing: border-box;">
                                    <mat-card *ngIf="invoice.pagar === true && invoice.tipo !== 'ANTI'"
                                        style="height: auto;padding: 2vh;margin: 5px; background-color: rgb(60,192,107); color: white;">

                                        <div class="row">
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px;">
                                                <b>Factura</b>
                                            </div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:right;padding-right: 40px"><b>{{
                                                    invoice.tipo | uppercase}} {{ invoice.numero}}</b></div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px;"><b>Valor a
                                                    pagar</b></div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:right;padding-right: 40px"><b> {{
                                                    invoice.valor_pagar | currency :'$'}}</b></div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px;"><b>Saldo</b>
                                            </div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:right;padding-right: 40px;"><b> {{
                                                    invoice.saldo - invoice.valor_pagar | currency :'$'}}</b></div>
                                        </div>

                                    </mat-card>

                                    <mat-card *ngIf="invoice.pagar === true && invoice.tipo === 'ANTI'"
                                        style="height: auto;padding: 2vh;margin: 2px; background-color:rgb(60,192,107); color: white;">

                                        <div class="row">
                                            <div class="col-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px;"><b>Anticipo
                                                    por otro concepto</b></div>
                                            <div class="col-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px"><b>Razón
                                                    anticipo: </b>{{invoice.motivo_anticipo}}</div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:left;padding-left: 40px;"><b>Valor a
                                                    pagar</b></div>
                                            <div class="col-6 col-md-6 col-sm-12"
                                                style="font-size:1rem;text-align:right;padding-right: 40px"><b> {{
                                                    invoice.valor_pagar | currency :'$'}}</b></div>
                                        </div>


                                    </mat-card>

                                </div>

                            </div>

                        </div>
                        <hr class="m-0 mt-2">

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <span class="title-payment"
                                    [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold','font-size':'16px'}">Total
                                    a Pagar:{{totalPayment | currency :'$'}}</span>
                            </div>                        
                        </div>

                        <div class="row mt-4">

                            <div class="col-md-6 mb-4">
                                <button [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton}" [disabled]="statusBotonModificar"
                                    class="btn btn-fill" type="submit" (click)="return()">Modificar</button>
                            </div>
                            <div class="col-md-6">

                                <button *ngIf="typePayment === 'PZ'" [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton}"
                                    class="btn btn-fill" type="submit" (click)="linkToPaymentezPay()">Ir a
                                    pagar</button>
                                    
                                <button *ngIf="typePayment === 'OP'" [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton}"
                                    class="btn fill" type="submit" (click)="linkToOpenPay()">Ir a pagar
                                </button>

                                <form *ngIf="typePayment === 'WO'" action="https://checkout.wompi.co/p/" method="GET">                        
                                    <input type="hidden" name="public-key" value={{publicKey}}/>
                                    <input type="hidden" name="currency" value="COP" />
                                    <input type="hidden" name="amount-in-cents" id="amount-in-cents" value="{{totalPayment * 100}}" />
                                    <input type="hidden" name="reference" id="referenceId" value="{{referenceId}}"/>
                                    <input type="hidden" name="signature:integrity" value="{{claveCriptografica}}"/>
                                    

                                    <input type="hidden" name="redirect-url" id="redirecUrl" value="{{urlRedirect + 'payments'}}"/> 
                                    <button [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton}" class="btn fill"  type="submit" (click)="statusBotonModificar=true" >Ir a pagar</button>
                                </form> 


                            </div>


                        </div>

                    </div>
                </div>

                
            </div>

        </div>

    </div>

    <h1 [ngStyle]="{'color': 'gray','font-family':fuente,'font-weight': 'bold', 'text-align':'center', 'margin-top':'30px' } ">No olvide dar clic en el boton  FINALIZAR  que aparece cuando su transacción sea aprobada</h1>

</div>