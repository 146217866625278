import { Injectable } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { Business } from '../../intefaces/GeneralInterface';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private subjectBusiness    = new Subject<Business>();
  //Messages for Bussines
  sendMessageBussines(business: Business) {
    this.subjectBusiness.next(business);
  }
  clearBussines() {
    this.subjectBusiness.next();
  }
  getMessageBussines(): Observable<Business> {
    return this.subjectBusiness.asObservable();
  }
}
