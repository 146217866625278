import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OperationsDataService } from './services/operations-data/operations-data.service';
import { MessageService } from './services/message-services/message-service.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Pasarela Pagos';
  private _paymentToken;
  private _idPayment;
  private _idPaymentez;
  private _idOPenPay;
  private _status: string = '';

  constructor(
    private operationData: OperationsDataService,
    private activatedRoute: ActivatedRoute,
    private messageService: MessageService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      this._paymentToken = params['paymentToken'];
      
      if(this._paymentToken != undefined){
        localStorage.setItem('_paymentToken',this._paymentToken);
      };

      this._idPayment = params['id'];
      this._idPaymentez = params['idPaymentez'];
      this._idOPenPay = params['order_id'];
      this._status = params['status'];

      if (this._paymentToken) {
        this.operationData
          .getBusiness(this._paymentToken)
          .subscribe((_data) => {
            this.messageService.sendMessageBussines(_data);
          });
      } else {
        if (typeof this._idPaymentez !== 'undefined') {
          this.router.navigate(['paymentez', this._idPaymentez, this._status]);
        } else if (typeof this._idOPenPay !== 'undefined') {
          console.log('vengo del inicio')
          this.router.navigate([
            'openpay',
            this._idOPenPay,
            this._idPayment,
            this._status,
          ]);
        }
      }
    });
  }
}
