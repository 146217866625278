<div class="container-fluid" style="height: 100%;">
  <div class="bg-image p-5 text-center shadow-1-strong text-white bglogin size-image"
    style="background-image: url('../../../assets/img/{{fondo}}'); height: 100%; background-repeat:no-repeat;  width: 100%; background-size:cover; background-position:center;">
    <div class="container-fluid">
      <div class="row mt-5">
        <div class="col-md-6 col-sm-12 margin-login" >
          <img width="100%" height="100px" [src]='logoCliente' />
        </div>
        <div class="col-md-6 col-sm-12">
          <div class="text-center size-card" >
            <div [ngStyle]="{ 'font-family': fuente, 'border-radius': '22px 22px 0px 0px', 'background-color':colorMarca, 'font-size':'30px' }" class="card-header">
              <p>Resumen de la transacción </p>
            </div>
            <div class="card-body bg-white" style="border-radius: '0px 0px 22px 22px';">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12" [ngStyle]="{ 'font-family': fuente, 'font-size':'20px','font-weight':'bold !important' }">
                    <h4 *ngIf="status=='APPROVED'" class="color-approved">Su pago a sido realizado con éxito </h4>
                    <h4 *ngIf="status=='DECLINED'" class="color-declined">Su transacción a sido rechazada</h4>
                    <h4 *ngIf="status=='PENDING'" class="color-declined">Su transaccion esta pendiente </h4>
                    <hr class="m-0 mt-2 mb-2">
                    <h6 slot="font-weight:800 " [ngStyle]="{ 'font-family': fuente, 'font-size':'15px' ,'font-weight': 'bold','color':'#808080'}">A continuación conocerá el
                      detalle de su pago</h6>

                  </div>

                </div>

                <div class="row"
                  style="color: black;  font-size: 10px !important; font-family: Arial, Helvetica, sans-serif;">
                  <div class="col-6 data-payment-left">Cédula</div>
                  <div class="col-6 data-payment-right">{{paymentSummary.nit}}</div>
                  <div class="col-6 data-payment-left">Fecha</div>
                  <div class="col-6 data-payment-right">{{date_transacction}}</div>
                  <div class="col-6 data-payment-left">Estado</div>

                  <!--Status-->
                  <div *ngIf="status=='APPROVED'" class="col-6 data-payment-right color-approved">{{status}}</div>
                  <div *ngIf="status=='DECLINED'" class="col-6 data-payment-right color-declined">{{status}}</div>
                  <div *ngIf="status=='PENDING'" class="col-6 data-payment-right color-declined">{{status}}</div>
                  <!--FIN-->

                  <div class="col-6 data-payment-left">Referencia</div>
                  <div class="col-6 data-payment-right" style="font-size: 0.90rem !important;">{{
                    paymentSummary.id_transaccion}}</div>
                  <div class="col-6 data-payment-left">Valor Pagado</div>
                  <div class="col-6 data-payment-right">{{ paymentSummary.valor_total | currency :'$' }}
                  </div>
                </div>
              </div>
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12" [ngStyle]="{ 'font-family': fuente, 'font-size':'20px','font-weight':'bold !important' }">
                    <h6 *ngIf="status==='APPROVED'" class="color-approved text-footer mt-4">Gracias por su pago.
                      Recibirá
                      copia
                      del comprobante de pago en su email</h6>
                    <h6 *ngIf="status==='DECLINED'" class="color-declined text-footer mt-4">Lo sentimos !!! No ha sido
                      posible
                      procesar su pago. Inténtelo nuevamente.</h6>

                    <h6 *ngIf="status==='PENDING'" class="color-declined text-footer mt-4">Estamos procesando el estado
                      de su
                      pago</h6>
                  </div>

                </div>
                <hr class="m-0 mb-2 ">

                <div class="row">
                  <div class="col-md-6 col-12 mb-2">
                    <button mat-stroked-button [ngStyle]="colorBotonEmpresa" class="btn btn-block"
                      (click)="newPayment()">Realizar otro pago</button>
                  </div>
  
                  <div class="col-md-6 col-12">
                    <button mat-stroked-button [ngStyle]="colorBotonEmpresa" class="btn btn-block" 
                      (click)="returnPageBussines()">{{textButton}}</button>
                  </div>
  
                </div>

              </div>


             

             

            </div>



          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="">
    <mat-toolbar   style="align-content: center !important;">
      <mat-toolbar-row [ngStyle]="colorEmpresa">
        <span style="font-size: 1.8rem;">Resumen de la transacción</span>
      </mat-toolbar-row>
    </mat-toolbar>
  
    <div class="info-payment">
      <div class="container-info-payment">
        <div class="container">
          <h2 *ngIf="status=='APPROVED'" class="color-approved text-header">SU PAGO HA SIDO REALIZADO CON ÉXITO</h2>
          <h2 *ngIf="status=='DECLINED'" class="color-declined text-header">SU TRANSACCION HA SIDO RECHAZADA</h2>
          <h2 *ngIf="status=='PENDING'" class="color-declined text-header">SU TRANSACCION ESTA PENDIENTE</h2>
          <br>
          <br>
          <h3 slot="font-weight:800">A continuación conocerá el detalle de su pago</h3>
          <br>
          <div class="row container-data-payment">            
            <div class="col-6 data-payment-left"><b>Cédula</b></div>
            <div class="col-6 data-payment-right"><b>{{paymentSummary.nit}}</b></div>
            <div class="col-6 data-payment-left"><b>Fecha</b></div>
            <div class="col-6 data-payment-right"><b>{{date_transacction}}</b></div>
            <div class="col-6 data-payment-left"><b>Estado</b></div>
            <div class="col-6 data-payment-right"><b [ngClass]="{'color-approved': status==='APPROVED', 'color-declined': status==='DECLINED', 'color-declined': status==='PENDING'}">{{status}}</b></div>
            <div class="col-6 data-payment-left"><b>Referencia</b></div>
            <div class="col-6 data-payment-right"><b>{{ paymentSummary.id_transaccion}}</b></div>
            <div class="col-6 data-payment-left"><b>Valor Pagado</b></div>
            <div class="col-6 data-payment-right"><b>{{ paymentSummary.valor_total | currency :'$' }}</b></div>
          </div>
        </div>    
        <h4 *ngIf="status==='APPROVED'" class="color-approved text-footer">Gracias por su pago. Recibirá copia del comprobante de pago en su email</h4>
        <h4 *ngIf="status==='DECLINED'" class="color-declined text-footer">Lo sentimos !!! No ha sido posible procesar su pago. Inténtelo nuevamente.</h4>
      </div>
  </div>
    <div class="footer" [ngStyle]="colorEmpresa"> 
        <div class="container-buttons-payment">         
            <button  mat-stroked-button [ngStyle]="colorBotonEmpresa" class="button-payment" (click)="newPayment()">Realizar otro pago</button>
            <button  mat-stroked-button [ngStyle]="colorBotonEmpresa" class="button-payment" (click)="returnPageBussines()">{{textButton}}</button>
        </div>
       </div>
  </div>

 -->