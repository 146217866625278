import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import * as moment from 'moment';
import { OperationsDataService } from '../../services/operations-data/operations-data.service'
import { AdvanceReason,Business, Invoices } from 'src/app/intefaces/GeneralInterface';
import { Guid } from "guid-typescript";

import * as $ from 'jquery';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  @ViewChild('observation', {read: ElementRef}) firstName: ElementRef<HTMLElement>;
  invoiceClient : string = "";
  nitClient : string = "";
  invoices: Invoices[] = [];
  advanceReason: AdvanceReason[] = [];
  color: "white";
  totalPayment : number = 0;
  tolalPaymentString: string = "";
  observationsAdvance: string = "";
  colorEmpresa: any;
  colorBotonEmpresa: any;
  private _bussines: Business;
  private referenceId: Guid;
  //new Mateo
  //new Mateo
  logoCliente:string='';
  imagenFondo:string='';
  titulo1:string='';
  texto1:string='';
  fuente:string='Arial';
  colorMarca:string='';
  colorTexoMarca:string='';
  colorFondoBoton:string='';
  colorTextoBoton:string='';

  constructor(private router: Router,
              private operationData: OperationsDataService) { }

 
  ngOnInit(): void {
    this.getBussines();
    this.getAdvanceReason();
    this.getInvoices();
    this.referenceId =  Guid.create();
    $("#referenceId").val(this.referenceId.toString());
  }

  private getBussines() {
    this._bussines = this.operationData.getDataBussines();
    if(this._bussines == undefined){
      this.router.navigate(['/'],{queryParams:{paymentToken:localStorage.getItem('_paymentToken')}});
    };

    this.colorEmpresa =   {'background-color' : this._bussines.color_background_marca , 'color' : this._bussines.color_texto_marca};
    this.colorBotonEmpresa =   {'background-color' : this._bussines.color_background_boton , 'color' : this._bussines.color_texto_boton};
      this.logoCliente = `../../../assets/img/${this._bussines.logoEmpresa}`;
      this.imagenFondo = this._bussines.fondo_inicial;
      this.titulo1 = this._bussines.titulo_1;
      this.texto1 = this._bussines.texto_1;
      this.fuente=this._bussines.nombre_fuente;
      this.colorMarca = this._bussines.color_background_marca;
      this.colorTexoMarca = this._bussines.color_texto_marca;
      this.colorFondoBoton = this._bussines.color_background_boton;
      this.colorTextoBoton = this._bussines.color_texto_boton;

  }

  private getInvoices() {
    let auxInvoice :Invoices[] = this.operationData.getDataInvoice();
    
    auxInvoice.forEach((item)=>{
      if (item.tipo.length > 0) {
        item.pagar =false;
        item.valor_pagar = item.saldo;
        item.fecha_aux = moment(item.fecha).format('DD/MM/YYYY');
        item.vence_aux = moment(item.vence).format('DD/MM/YYYY');
        this.invoices.push(item);
        this.invoiceClient = item.cliente;
        this.nitClient = item.nit.toString();
      }
    })
  }

  private getAdvanceReason(){
     this.advanceReason = this.operationData.getDataAdvanceReason();
  }

  onCheckboxChange(e,numero) {
    
    if (e.target.checked) {
      $("#" + numero + "pagar").prop( "disabled", false );
      $("#" + numero + "pagar").prop( "checked", true );
      $("#" + numero + "modificar").prop( "disabled", false );
      let order = this.invoices.filter(invoice=> invoice.numero === numero)[0];
      $("#" + numero ).val(parseFloat(order.saldo.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"));
      this.generareValuePayment(e.target.value,"+");
      order.pagar = true;
      order.valor_pagar =  order.saldo; 
    } else {

      $("#" + numero + "pagar").prop( "disabled", true );
      $("#" + numero + "modificar").prop( "disabled", true );
      let order = this.invoices.filter(invoice=> invoice.numero === numero)[0];
      let currentValue = $("#" + numero ).val();
      currentValue = parseFloat(currentValue.toString().replace(/,/g, ''));
      currentValue =  currentValue.toFixed(2);
      order.pagar = false;
      order.valor_pagar =  0;

      if (currentValue !== order.saldo.toString()){
         this.generareValuePayment(currentValue,"-");
      } else {
        this.generareValuePayment(e.target.value,"-");
      }
    } 
  }

  onCheckboxHNChange(e,numero,hN_seq_forma) {
    if (e.target.checked) {
      $("#" + numero.toString() +  hN_seq_forma.toString() + "pagar").prop( "disabled", false );
      $("#" + numero.toString() +  hN_seq_forma.toString() +  "pagar").prop( "checked", true );
      $("#" + numero.toString() +  hN_seq_forma.toString() +  "modificar").prop( "disabled", false );
      let order = this.invoices.filter(invoice=> invoice.numero === numero && invoice.hN_seq_forma === hN_seq_forma)[0];
      $("#" + numero.toString() +  hN_seq_forma.toString()  ).val(parseFloat(order.saldo.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"));
      this.generareValuePayment(e.target.value,"+");
      order.pagar = true;
      order.valor_pagar =  order.saldo; 
    } else {

      $("#" + numero.toString() +  hN_seq_forma.toString() + "pagar").prop( "disabled", true );
      $("#" + numero.toString() +  hN_seq_forma.toString() + "modificar").prop( "disabled", true );
      let order = this.invoices.filter(invoice=> invoice.numero === numero  && invoice.hN_seq_forma === hN_seq_forma)[0];
      let currentValue = $("#" + numero.toString() +  hN_seq_forma.toString()).val();
      currentValue = parseFloat(currentValue.toString().replace(/,/g, ''));
      currentValue =  currentValue.toFixed(2);
      order.pagar = false;
      order.valor_pagar =  0;

      if (currentValue !== order.saldo.toString()){
         this.generareValuePayment(currentValue,"-");
      } else {
        this.generareValuePayment(e.target.value,"-");
      }
    } 
  }

  modificarValor(e,numero) {
    
    if (e.target.checked) {
      $("#" + numero ).prop( "disabled", false );
      $("#" + numero ).focus().select();
    }else{
      $("#" + numero ).prop( "disabled", true );
    }
  }

  pagarSaldo(e,numero) {
    
    if (e.target.checked) {
      $("#" + numero ).prop( "disabled", true );
    }else{
      $("#" + numero ).prop( "disabled", false );
    }
  }

  modificarValorHN(e,numero,hN_seq_forma) {
    
    if (e.target.checked) {
      $("#"  + numero.toString() + hN_seq_forma.toString()).prop( "disabled", false );
      $("#"  + numero.toString() + hN_seq_forma.toString()).focus().select();
    }else{
      $("#" + numero ).prop( "disabled", true );
    }
  }

  pagarSaldoHN(e,numero,hN_seq_forma) {
    
    if (e.target.checked) {
      $("#" +  numero.toString() + hN_seq_forma.toString()  ).prop( "disabled", true );
    }else{
      $("#" + numero.toString() +  hN_seq_forma.toString() ).prop( "disabled", false );
    }
  }

  onCheckboxChangeOther(e) {
    let order = this.invoices.filter(invoice=> invoice.tipo === "ANTI")[0];
    if (e.target.checked) {
      $("#otherValue").prop( "disabled", false );
      $("#otherValue").val("0.00");
      $("#otherValue" ).focus().select();
      order.pagar = true;
    } else {
      let currentValue = $("#otherValue").val();
      currentValue = parseFloat(currentValue.toString().replace(/,/g, ''));
      this.generareValuePayment(currentValue,"-");

      $("#otherValue").prop( "disabled", true );
      $("#otherValue").val("0.00");
      order.pagar = false;
    }
  }

  onChangeReason(value){
   
    let order = this.invoices.filter(invoice=> invoice.tipo === "ANTI")[0];
    
    if (parseInt(value) !== 0){
      let reason =  this.advanceReason.filter(reason=> reason.id === parseInt(value))[0].motivo_anticipo;
      let cta_anticipo =  this.advanceReason.filter(reason=> reason.id === parseInt(value))[0].cta_contable;
      order.id_motivo_anticipo = value;
      order.motivo_anticipo = reason;
      order.cta_anticipo = cta_anticipo;
    }else {
      
      order.id_motivo_anticipo = 0;
      order.motivo_anticipo = "";
      order.cta_anticipo = "";
    }
  }

  viewPaymentSummary() {
    let orders:Invoices[] = this.invoices.filter(invoice=> invoice.pagar === true);
    
    if (orders.length == 0){
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Por favor seleccione las facturas o anticipos a pagar!'
      })
      return;
    }
    
    let order = this.invoices.filter(invoice=> invoice.tipo === "ANTI")[0];
    let observation = $('textarea#observation').val();
    
    if (order.pagar === true) {
      
      if ( order.valor_pagar === 0){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Por favor ingrese el valor a pagar del anticipo !'
        })
        return;
      }

       if (typeof order.id_motivo_anticipo === "undefined" && observation.toString().length === 0){
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Por favor seleccione un motivo de anticipo y agregue observación!'
            })
            return;
        }

        if (typeof order.id_motivo_anticipo === "undefined" && observation.toString().length > 0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por favor seleccione un motivo de anticipo!'
          })
          return;
        }

        if ( order.id_motivo_anticipo === 0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por favor seleccione un motivo de anticipo!'
          })
          return;
        }

        if (typeof order.id_motivo_anticipo !== "undefined" && observation.toString().length === 0){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por favor ingrese observacion!'
          })
          return;
        }

        if ( observation.toString().length < 5){
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Por favor ingrese observación, superior a 5 caracteres !'
          })
          return;
        }

        
        order.notas = observation.toString();
    }
    
    this.router.navigate(['/payment-summary']); 
  }

  validatedValue(value: string,numero){
    
    if(value=='0'){      
      $("#check" + numero).prop( "disabled", false );
      $("#check" + numero).prop( "checked", false );
      $("#" + numero + "pagar").prop( "disabled", true );
      $("#" + numero + "pagar").prop( "checked", true );
      $("#" + numero + "modificar").prop( "disabled", false );
      let order = this.invoices.filter(invoice=> invoice.numero === numero)[0];
      $("#" + numero ).val("$"+parseFloat(order.saldo.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"));
      $("#" + numero ).prop("disabled",true);
      order.pagar = false;
      order.valor_pagar =  0;
      //$("#check" + numero + "modificar").prop( "disabled", false );
    }
   
     let order = this.invoices.filter(invoice=> invoice.numero === numero)[0];
     if (parseFloat(value.replace(/,/g, '')) > order.saldo){
       $("#" + order.numero).val(parseFloat(order.saldo.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"));
     } else {
        order.valor_pagar = parseFloat(value.replace(/,/g, ''));
        this.generareValuePayment(order.saldo,"-");
        this.generareValuePayment(parseFloat(value.replace(/,/g, '')),"+");
     }

     
     
  }

  validatedValueHN(value: string,numero, hN_seq_forma){
   
    let order = this.invoices.filter(invoice=> invoice.numero === numero && invoice.hN_seq_forma === hN_seq_forma)[0];
    if (parseFloat(value.replace(/,/g, '')) > order.saldo){
      $("#" + order.numero + order.hN_seq_forma).val(parseFloat(order.saldo.toString()).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,"));
    } else {
       order.valor_pagar = parseFloat(value.replace(/,/g, ''));
       this.generareValuePayment(order.saldo,"-");
       this.generareValuePayment(parseFloat(value.replace(/,/g, '')),"+");
    }
 }

  validatedOtherValue(value: string){
    let order = this.invoices.filter(invoice=> invoice.tipo === "ANTI")[0];
    order.valor_pagar = parseFloat(value.replace(/,/g, ''));
    this.generareValuePayment(parseFloat(value.replace(/,/g, '')),"+");
  }

  generareValuePayment(value,operation){

     let accepted_values = /^[0-9]+$/;
     if (operation === "+") {
        this.totalPayment = this.totalPayment + parseFloat(value); 
     }else {
        this.totalPayment = this.totalPayment - parseFloat(value); 
     }
     //this.tolalPaymentString = this.totalPayment.toString() + "00";
     
     //if (this.tolalPaymentString.match(accepted_values)){
       
     //} else {
     //  this.tolalPaymentString = this.totalPayment.toString();
    // }
     this.tolalPaymentString = this.tolalPaymentString.replace('.','');
     this.tolalPaymentString = this.tolalPaymentString.substring(0,this.totalPayment -2);
     
     $("#amount-in-cents").val(this.tolalPaymentString);
  }
}
