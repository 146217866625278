<div class="container-fluid" style="height: 100%;">
    <div class="bg-image p-5 text-center shadow-1-strong text-white bglogin size-image"
        style="background-image: url('../../../assets/img/{{imagenFondo}}'); background-repeat:no-repeat;  width: 100%; background-size:cover; background-position:center;">
        <div class="container-fluid">
            <div class="row mt-md-5 ">
                <div class="col-md-6 col-sm-12 margin-login">
                    <img width="100%" height="100px" [src]='logoCliente' />
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="text-center size-card">
                        <h4 [ngStyle]="{ 'font-family': fuente }" >{{titulo1}} </h4>
                        <p [ngStyle]="{ 'font-family': fuente, 'font-size':'14px' }" >{{texto1}}</p>
                        <div  class="card-header " [ngStyle]="{ 'font-family': fuente, 'border-radius': '22px 22px 0px 0px', 'background-color':colorMarca }">
                            <p [ngStyle]="{ 'font-family': fuente, 'margin':'0px', 'font-size':'30px' }">Módulo de pago</p>
                        </div>
                        <div class="card-body bg-white">
                            <div *ngIf="isvalidBrowser" class="container-fluid">
                                <form>
                                    <div class="form-group" style="text-align: left; color: black;">
                                        <label for="exampleInputEmail1" [ngStyle]="{ 'font-family': fuente }">Tipo de Documento</label>
                                        <div class="input-group">
                                            <select class="form-control py-2 border-right-0 border"
                                                id="exampleFormControlSelect1">
                                                <option value="N">Nit</option>
                                                <option value="C">Cédula de Ciudadanía</option>
                                                <option value="E">Cédula de Extranjería</option>
                                                <option value="P">Pasaporte</option>
                                                <option value="TI">Tarjeta de identidad</option>
                                                <option value="OT">Otro</option>
                                            </select>
                                            <span class="input-group-append">
                                                <div class="input-group-text bg-transparent"><img
                                                        src="../../../assets/img/Documento.svg" /></div>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="form-group" style="text-align: left; color: black;">
                                        <label for="exampleInputEmail2" [ngStyle]="{ 'font-family': fuente }">Número de Documento</label>
                                        <div class="input-group">
                                            <input class="form-control py-2 border-right-0 border" placeholder="10234567890"
                                                id="identificationNumber" (keypress)="onKeyPressInput($event)" (keyup)="enabledButton($event)">
                                            <span class="input-group-append">
                                                <div class="input-group-text bg-transparent"><img
                                                        src="../../../assets/img/Documento.svg" /></div>
                                            </span>
                                        </div>
                                    </div>

                                </form>

                            </div>
                        </div>
                        <div class="card-footer text-muted"[ngStyle]="{ 'font-family': fuente, 'border-radius': '0px 0px 22px 22px', 'background-color':colorMarca }"  >
                            <button type="button" mat-stroked-button [disabled]="disabledBtnContinuar" [ngStyle]="{ 'font-family': fuente, 'background-color':colorFondoBoton, 'color':colorTextoBoton }"
                                class="btn button-login btn-block" (click)="onSubmit()">Continuar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    
    <footer class="bg-light text-center text-lg-start" >
        <!-- Copyright -->
        <div class="text-center p-3 text-white" [ngStyle]="{'background-color':colorMarca, 'color':colorTexoMarca}">
            <div class="row">
                <div class="col-12 text-center">
                    Navegadores Disponibles
                </div>
            </div>            
            <div class="container mt-2">
                <div class="row text-center">
                    <div class="col-3">
                        <img src="../../../assets/img/IconoFirefox.svg" alt="">
                    </div>

                    <div class="col-3">
                        <img src="../../../assets/img/IconoChrome.svg" alt="">
                    </div>

                    <div class="col-3">
                        <img src="../../../assets/img/Iconoedge.svg" alt="">
                    </div>

                    <div class="col-3">
                        <img src="../../../assets/img/IconoSafari.svg" alt="">
                    </div>

                </div>

            </div>
        </div>
        <!-- Copyright -->
    </footer>
</div>