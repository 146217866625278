import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AdvanceReason,
  Business,
  Invoices,
  PaymentSummary,
} from '../../intefaces/GeneralInterface';
import { DaviviendaLinkPayRequest } from 'src/app/intefaces/DaviviendaPayInterface';
import { OpenPayLinqRequest, OpenPayValidatePayment } from 'src/app/intefaces/OpenPayInterface';
import { data } from 'jquery';
/* import { DaviviendaLinkPayRequest } from 'src/app/intefaces/DaviviendaPay'; */

//const URLSERVICIO = 'https://www.mmc-pasarela.com/PasarelaPagosApi/api/';
//const URLSERVICIO = 'http://139.99.121.175:8087/PasarelaPagosApi/api/';
//const URLSERVICIO = 'http://dmsweb.digital:8087/PasarelaPagosApi/api/';
const URLSERVICIO = 'http://prueba.mmc-pasarela.com/PasarelaPagosApi/api/';
//const URLSERVICIO = 'http://localhost:49980/api/';
/*const URLSERVICIO = 'http://localhost:49980/api/';*/
const URLWOMPY = 'https://sandbox.wompi.co/v1/'; //
//const URLWOMPY = "https://production.wompi.co/v1/";

@Injectable({
  providedIn: 'root',
})
export class OperationsDataService {
  private business: Business;
  private advanceReason: AdvanceReason[];
  private invoices: Invoices[] = [];
  private paymentSummary: PaymentSummary;
  private validatePayment: OpenPayValidatePayment;

  constructor(private Http: HttpClient) { }

  public getBusiness(businessToken: string): Observable<Business> {
    const Url = `${URLSERVICIO}${'Bussines/bussines?Token'}=${businessToken}`;
    return this.Http.get<Business>(Url).pipe(
      map((data) => (this.business = data))
    );
  }

  public getAdvanceReason(business: number): Observable<AdvanceReason[]> {
    const Url = `${URLSERVICIO}${'Bussines/advancereason?idBussines'}=${business}`;
    return this.Http.get<AdvanceReason[]>(Url).pipe(
      map((data) => (this.advanceReason = data))
    );
  }

  public getInvoices(
    identificationNumber: string,
    dataBase: string
  ): Observable<Invoices[]> {
    const Url = `${this.business.url_servicio}${'Documents/documents?nit'}=${identificationNumber}&database=${dataBase}`;
    return this.Http.get<Invoices[]>(Url).pipe(
      map((data) => (this.invoices = data))
    );
  }

  public documentPaymentSave(documentsPayment: any): Observable<any> {
    const Url = `${URLSERVICIO}${'PaymentsCloud/documents'}`;
    return this.Http.post(Url, documentsPayment).pipe();
  }

  public updatedocumentsPayModify(idTransaccion: string): Observable<any> {
    //const Url = `${URLSERVICIO}${'PaymentsCloud/documentsPayModify?id_transaccion'}=${idTransaccion}`;
    //return this.Http.patch<any>(Url).pipe(
    //  map( ( data ) => data));
    const Url = `${URLSERVICIO}${'PaymentsCloud/documentsPayModify'}`;
    const transaccion = { id_transaccion: idTransaccion };
    return this.Http.patch(Url, transaccion).pipe();
  }

  public getPaymentSummary(idTransaccion: string): Observable<PaymentSummary> {
    const Url = `${URLSERVICIO}${'PaymentsCloud/paymentsummary?idTransacction'}=${idTransaccion}`;
    return this.Http.get<PaymentSummary>(Url).pipe(
      map((data) => (this.paymentSummary = data))
    );
  }

  public documentPaymentSaveErp(documentsPayment: any): Observable<any> {
    const Url = `${URLSERVICIO}${'PaymentsCloud/documentsPay'}`;
    return this.Http.post(Url, documentsPayment).pipe();
  }

  public saveFailedWompy(documentsPayment: any): Observable<any> {
    const Url = `${URLSERVICIO}${'PaymentsCloud/documentsPayFailed'}`;
    return this.Http.post(Url, documentsPayment).pipe();
  }

  public generateLinkToPay(
    linktoPay: DaviviendaLinkPayRequest
  ): Observable<any> {
    const Url = `${URLSERVICIO}${'V1/DaviviendaPayment/generatelinktopay?idBussiness='}${this.business.id_empresa
      }`;
    return this.Http.post(Url, linktoPay).pipe();
  }

  public generateLinkToOpenPay(linktoPay: OpenPayLinqRequest): Observable<any> {
    const Url = `${URLSERVICIO}${'V1/OpenPayPayment/generatelinktopay?idBussiness='}${this.business.id_empresa
      }`;
    return this.Http.post(Url, linktoPay).pipe();
  }

  public getTransacctions(transaction_id: string): any {
    const Url = `${URLWOMPY}${'transactions'}/${transaction_id}`;
    return this.Http.get<any>(Url).pipe();
  }

  public validateOpenPayPayment(idTransaccion: string): Observable<OpenPayValidatePayment> {
    const Url = `${URLSERVICIO}${'V1/OpenPayPayment/validate?idTransacction='}${idTransaccion}`;
    return this.Http.get<OpenPayValidatePayment>(Url).pipe();
  }

  public getDataInvoice(): Invoices[] {
    return this.invoices;
  }

  public getDataBussines(): Business {
    return this.business;
  }

  public getDataAdvanceReason(): AdvanceReason[] {
    return this.advanceReason;
  }

  public getStatusInvoice(idTransaccion: string) {
    const Url = `${URLSERVICIO}${'V1/ConnectDataCloud/validate?idtransaccion='}${idTransaccion}`;
    return this.Http.post(Url, '');
  }

  public setLogDataWompi(body) {
    const Url = `${URLSERVICIO}${'PaymentsCloud/SetLogDataWompi'}`;
    console.log(Url);
    return this.Http.post(Url,body);
  }


  public GenerarFirmaCriptografica(body){
    const Url = `${URLSERVICIO}${'Transactions/GenerarFirma'}`;
    return this.Http.post(Url,body,{  responseType : 'text'});
  }



}

